.partner {
  &__top {
    display: flex;
    margin-top: -1rem;
    justify-content: flex-end;
    gap: 1%;
  }

  &__tabs {
    margin-top: 1%;
  }

  span {
    color: #83889d;
  }

  &__modal {
    position: relative;
    bottom: 2rem;

    &__text {
      h1,
      p {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
    }

    h1 {
      font-size: 27px;
    }
  }

  &__modal__btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5%;

    button {
      width: 12rem;
      padding: 15px;
    }
  }

  &__search {
    display: flex;
    justify-content: space-between;
    margin-top: 2%;

    &__child {
      display: flex;
      justify-content: space-between;
      gap: 3%;
    }
  }

  &__count {
    color: #425466 !important;
    font-weight: bolder;
    font-size: 18px;
    position: relative;
    text-transform: uppercase;
  }

  &__table {
    margin-top: 2%;

    &__status {
      display: flex;
    }
  }
}

.partner_details {
  &__top {
    display: flex;
    gap: 2%;

    h1 {
      color: #364469;
    }

    img {
      cursor: pointer;
    }
  }

  &_document {
    margin-top: 2%;
  }

  &_procedure_wrap {
    background: #fafafa;
    border: 1px solid #edf2f7;
    border-radius: 4px;
    height: 100%;
    overflow: scroll;
    padding: 10px;
    margin-top: 2%;

    h1 {
      color: #364469;
      font-size: 18px;
    }

    &_list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1%;
    }

    span {
      color: #83889d;
    }
  }

  &__filter {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin: 0;
    }
  }
}

.partner__menu {
  background: #fafafa;
  border-radius: 4px;
  padding: 20px;
  display: flex;

  &__top {
    display: flex;
    flex-direction: row;
    gap: 5%;

    &__child {
      display: flex;
      gap: 3%;
    }

    span {
      color: #83889d;
      margin-left: 2.5rem;
      position: relative;
      bottom: 17%;
    }

    button {
      margin-left: 2.5rem;
      background: #edf2f7;
      border-radius: 24px;
      border: none;
      color: #ed9373;
      padding: 7px;
      cursor: pointer;
    }
  }

  &_modal {
    display: flex;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }
}

.status__indicator {
  display: flex;

  &__item {
    position: relative;
    bottom: 15px;
  }

  &__dot {
    position: relative;
    bottom: 3px;
    cursor: pointer;
  }
}

.invite_partner {
  min-height: 80vh;
  padding: 24px;
  background: #ffffff;
}

.mainform {
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: center;
  margin: 39px auto;

  &__input {
    // padding-bottom: 10px;
    padding: 10px;
  }

  &__select {
    width: 50%;
  }

  &__double {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
  }

  &__double1 {
    width: 50%;
  }

  &__button {
    margin-top: 20px;

    button {
      min-width: 385px !important;
      position: relative;
      left: 1.5%;
    }
  }
}

.package {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  height: 50vh;
  overflow: scroll;
  top: 18rem;
  right: 7rem;

  &__none {
    display: none;
  }

  &__summary {
    background: #fafafa;
    border-radius: 8px;
    width: 372px;
    text-align: center;
    min-height: 372px;

    h3 {
      font-weight: 600;
      font-size: 20px;
      color: #364469;
    }

    &_item {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 10px;
      gap: 20px;
    }

    &_btn {
      button {
        background: #edf2f7;
        border: none;
        border-radius: 16px;
        padding: 12px;
        font-size: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 5px;
        font-weight: 500;
      }

      img {
        width: 20px;
        cursor: pointer;
      }
    }
  }
}

.filterBtn {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
}

.actionBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &__titleText {
    color: #83889d;
    font-size: 18px;
    font-weight: 600;
  }
}

.indicatorWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  text-transform: capitalize;
}

.dateContainer {
  width: 40%;
  display: flex;
  gap: 16px;
}

.btnContainer {
  display: flex;
  float: right;
  gap: 20px;
  margin-top: 20px;
}

.payment {
  &__modal {
    &__header {
      display: flex;
      justify-content: space-between;
      gap: 8rem;
      background: #fafafa;
      padding: 20px;
      position: absolute;
      left: 0;
      width: 92%;
      top: 0;

      img {
        cursor: pointer;
      }

      h1 {
        color: #27272e;
        font-size: 24px;
        margin-left: 1.5rem;
      }
    }
  }

  &__form {
    margin-top: 90px;
    padding: 10px 5px;

    &__item {
      padding: 5px;
    }
  }

  &__btn {
    button {
      width: 92%;
      padding: 15px;
      margin-left: 1rem;
      margin-top: 10px;
    }
  }
}

.invoice {
  &_card {
    padding: 20px;
    border: 1px solid #edf2f7;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;

    p {
      margin: 0;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 0.2fr));
    grid-template-rows: auto;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }
  &__status {
    display: flex;
    justify-content: space-between;
  }
  &__id {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #364469;
  }

  &__view {
    font-size: 12px;
    font-weight: 500;
    color: #83889d;
    line-height: 16px;
    text-decoration: underline;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    background: #edf2f7;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__emptyState {
    margin-top: 100px;
    text-align: center;
  }
}
