.container {
  width: 100%;
}

.label {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 15px;
}

.dropdown {
  position: relative;
  max-width: 448px;
  cursor: pointer;
}

.box {
  width: auto;
  border: 1px solid #cfddee;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__input {
    font-weight: 500;
    font-size: 13px;
  }
}

.ul {
  position: relative;
  max-width: 435px;
  top: 100%;
  left: 0px;
  z-index: 10;
  height: 70px;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  box-shadow: 0px 8px 8px -4px #0000000a;
  box-shadow: 0px 20px 24px -4px #0000001a;
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
  overflow: auto;
  width: 100%;
}

.time {
  padding: 12px;
  cursor: pointer;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &:hover {
    background-color: #e5e7eb;
  }
}

.selected {
  background-color: #e5e7eb;
  padding: 12px;
}
