.search {
  border: 1px solid #b5b5bd;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  color: #b5b5bd;
  font-weight: 400;
  font-size: 16px;
  width: 330px;
  input {
    border: none;
    outline: none;
    width: 100%;
  }
  input::placeholder {
    opacity: 1;
    color: #b5b5bd;
    font-size: 16px;
    line-height: 24px;
  }
}
