.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  color: #83889d;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;

  ul {
    display: flex !important;
    width: auto;
    list-style-type: none;
    color: #83889d;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
  }

  ul li {
    margin-right: 16px;
  }

  &__container {
    display: flex;
    width: unset;

    justify-content: space-between;
    align-items: center;

    input {
      width: 40px;
      padding: 2px;
      font-size: 14px;
      height: 20px;
      text-align: center;
    }
  }
}

.activePaginate {
  background: #f8d4c7;
  border-radius: 2px;
  padding: 0 8px;
  color: #27272e;
}
