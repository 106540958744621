.invoice {
  background: #ed9373;
  text-align: center;
  height: 150px;
  // position: relative;
  // margin: auto;
  width: 100%;

  &__close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    margin-top: -8.5rem;

    & img {
      width: 30px;
      cursor: pointer;
      margin: 0;
      margin-right: 1rem;
      border-radius: 10px;
    }
  }

  img {
    margin-top: 2rem;
  }

  &__heading {
    font-size: 14px;
    line-height: 16px;
    color: #fafafa;
    letter-spacing: 0.975em;
    font-weight: 500;
    margin-top: 6%;
  }

  &__main {
    margin: 1rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 3rem 0rem 1rem 0rem;
  }

  &__header_item,
  &__header_item1 {
    width: 100%;
    display: flex;
    align-items: baseline;
    font-style: normal;
    font-weight: 400;
    font-size: 12.5px;
    line-height: 18px;
    color: #425466;

    & img {
      padding-right: 0.8rem;
    }
  }

  &__header_item {
    width: 100%;
    height: 85px;
  }

  &__header_invoiceNo {
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
  }

  &__header_item1 {
    width: 100%;
  }

  &__header_text {
    text-align: left;
  }

  &__header_text1 {
    text-align: right;
  }

  &__header_span {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-transform: capitalize;

    & span {
      padding-right: 0.5rem;
      font-weight: 600;
      padding-top: 1rem;
    }

    & p {
      font-weight: 600;
      padding: 0px 0.5rem 0px 0px;
      margin: 0px;
      color: #83889da3;
    }
  }

  &__amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #425466;

    & div div {
      margin-bottom: 1rem;
    }
  }

  &__amount_box {
    width: 140px;
    height: 70px;
    background: #364469;
    text-align: center;
    padding: 0.5rem;
    margin-top: 1rem;

    & p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.02em;
      color: #ffffff;
    }

    & span {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #ffffff;
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  }

  &__details__button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 2rem 0px;
    padding-bottom: 2rem;
  }
}

.invoicetable {
  & table {
    width: 100%;
    padding: 0;
    border-collapse: collapse;
    color: #425466;
    table-layout: fixed;
    margin: 2rem 0px;
  }

  & thead tr {
    font-size: 1rem;
    color: #425466;
    border-bottom: 1px solid #c1c6d3;
  }

  & tbody tr {
    border-bottom: 1px solid #c1c6d3;
  }

  & th {
    letter-spacing: 0.075rem;
    color: #364469;
  }

  & th,
  & td {
    padding: 0.75rem 1rem;
    text-align: left;
    font-size: 12.8px;
  }

  & th:nth-child(4),
  & td:nth-child(4) {
    text-align: right;
  }
}