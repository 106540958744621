.packages {
  &__indicatorWrapper {
    display: flex;
    text-transform: capitalize;
  }

  &_create {
    &__heading {
      display: flex;
      align-items: center;

      img {
        cursor: pointer;
      }

      h1 {
        margin: 0 24px;
      }
    }

    &__packageProcedures {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      &__selector {
        width: 90%;
        height: 300px;
        background: #fafafa;
        border-radius: 8px;
        padding: 20px;
      }

      &__container {
        height: 210px;
        margin: 10px 0px;
        overflow-y: scroll;

        &__flex {
          display: flex;
          gap: 10px 20px;
          flex-wrap: wrap;
          width: 100%;
        }
      }

      &__item {
        background: #edf2f7;
        border-radius: 16px;
        margin-top: 10px;
        padding: 10px;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        text-transform: capitalize;
      }

      &__icon {
        padding-left: 10px;
      }

      &__empty {
        text-align: center;
        padding: 50px 0;
      }
    }

    &__summarybtn {
      display: flex;
      justify-content: flex-end;
    }

    &__details {
      // width: 95%;
      padding: 23px;
      // border: 1px solid #edf2f7;
      margin-top: 24px;
      border-radius: 5px;
      margin-bottom: 41px;

      &_form {
        display: block;
        width: 400px;
        margin: 0 auto;
      }

      &_form > div {
        margin-bottom: 25px;
      }
    }

    &__procedures {
      height: 600px;
      overflow: scroll;
    }
  }

  &_view_details {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  &_summary_details {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__heading {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #83889d;

      p {
        margin: 0;
      }
    }

    &__text {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #364469;
      text-transform: capitalize;
    }

    &__button {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.procedure {
  &__heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #364469;
    text-transform: capitalize;

    h2 {
      margin: 0;
      text-transform: capitalize;
    }
  }

  &__category {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #425466;
  }

  &__table {
    margin-bottom: 32px;

    th {
      font-weight: bolder;
    }

    td {
      background-color: #fafafa !important;
    }
  }

  &__wrap {
    background: #fafafa;
    border: 1px solid #edf2f7;
    border-radius: 4px;
    padding: 20px;
    height: 70vh;
    overflow: scroll;
    margin-top: 2%;

    &__heading {
      color: #364469;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;

      h1 {
        margin: 0;
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(242px, 0.2fr));
    gap: 1%;
    height: 50%;
    overflow: scroll;
  }

  &__details {
    margin: 50px 0;

    &__heading {
      font-size: 18px;
      margin: 20px 0 0 0;
      text-transform: capitalize;
    }

    &__caption {
      font-size: 14px;
      color: #83889d;
    }
  }
}

.createBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

.status {
  &__modal__button {
    display: flex;
    justify-content: space-between;

    button {
      padding: 14px 46px;
      width: 40%;
      outline: none;
      border: none;
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      border-radius: 8px;
    }

    &_save {
      background-color: #364469;
      color: white;
    }

    &_cancel {
      color: #425466;
    }
  }
}

.filterBtn {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
}

.actionBox {
  &__search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.indicator {
  display: flex;
  align-items: center;

  p {
    margin: 0 0 0 5px;
  }
}

.superpackages {
  &_create {
    &__heading {
      display: flex;
      align-items: center;

      img {
        cursor: pointer;
      }

      h1 {
        margin: 0 24px;
        font-size: 28px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #364469;
      }
    }
  }

  &_actionBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0 50px;
      color: #83889d;
    }
  }

  &_view_details {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px 20px;
    margin: 10px 0;
    padding: 0 0 15px 12px;
    border-bottom: 1px solid #364469;
  }

  &_summary_details {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__heading {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #83889d;

      p {
        margin: 0;
      }
    }

    &__text {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #364469;
      text-transform: capitalize;
    }

    &__button {
      display: flex;
      justify-content: flex-end;
    }
  }
}
