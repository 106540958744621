.avatar {
  &__placeholder {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    font-weight: 500;
    font-size: 14px;
    border: 2px solid #eef5ff;
    background: #ed9373;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0;
    position: relative;
  }

  &__close__img {
    position: absolute;
    left: 23rem;
    top: 10%;
    cursor: pointer;
  }

  &__profile {
    background: #ffe19c;
    width: 94px;
    height: 94px;
    font-weight: 500;
    font-size: 45px;
    padding: 4px;
    border: 2px solid #eef5ff;
    box-shadow: 0 0 0 10px #fff8e8;
    box-sizing: border-box;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
  }
}

.profile__img {
  width: 94px;
  height: 94px;
  font-weight: 500;
  font-size: 45px;
  padding: 4px;
  border: 2px solid #eef5ff;
  box-sizing: border-box;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
  object-fit: cover;
}

.header__img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  font-weight: 500;
  font-size: 14px;
  border: 2px solid #eef5ff;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0;
  position: relative;
  object-fit: cover;
}
