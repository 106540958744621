.websiteManagement {
  &__heading {
    color: #364469;
  }

  &__homePage {
    &__edit {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__createHeading {
      display: flex;
      gap: 12px;
      align-items: center;

      img {
        cursor: pointer;
      }
    }

    &__createContainer {
      display: flex;
      align-items: start;
      gap: 40px;
      margin-top: 56px;
      height: 70vh;
      overflow: scroll;
    }

    &__createCategory {
      border: 1px dashed #cfddee;
      padding: 40px 32px 43px 32px;
      cursor: pointer;
      border-radius: 16px;
      width: 278px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      color: #364469;
      margin-bottom: 10px;

      p {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        color: #364469;
        line-height: 24px;
      }
    }

    &__formContainer {
      border: 1px solid #e4ebf5;
      border-radius: 16px;
      background: #f7f9fb;
      padding: 54px 95px;
      width: auto;
    }

    &__createCategoryName {
      width: 422px;

      h2 {
        margin-bottom: 10px;
        color: #364469;
      }
    }
  }

  &__description {
    margin: 10px 0 20px 0;

    textarea {
      width: -webkit-fill-available;
      height: 150px;
      font-family: 'Inter', sans-serif;
      border: 1px solid #c1c6d3;
      outline: none;
      border-radius: 8px;
      padding: 12px 14px;
    }

    p {
      margin: 0 0 8px 0;
      font-size: 14px;
      color: #425466;
    }
  }

  &__view {
    &__heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      margin-bottom: 30px;
      color: #364469;

      div {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      img {
        cursor: pointer;
      }
    }

    &__categoryName {
      margin: 15px 0 25px 0;
      display: flex;
      align-items: center;
      gap: 10px;

      h5 {
        font-size: 18px;
        color: #83889d;
        margin: 5px 0 0 0;
      }
    }

    &__description {
      font-size: 16px;
      margin: 0 0 12px 0;
      color: #364469;
    }

    &__descriptionCaption {
      color: #425466;
      word-break: break-word;
    }

    &__procedureHeading {
      margin-bottom: 20px;
      color: #364469;
    }

    &__procedures {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      margin-bottom: 60px;

      &__card {
        width: 242px;
      }
    }
  }
}

.toggleRadio {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.categories {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 15px;
}

.single_category {
  background-color: #fafafa;
  border: 1px solid #edf2f7;
  border-radius: 8px;
  cursor: pointer;
  padding: 16px 20px 16px 20px;
  font-weight: 500;

  // &:hover {
  //   border: 1px solid #425466;
  // }

  &__caption {
    display: flex;
    justify-content: space-between;

    h6 {
      margin: 0;
    }

    &__category {
      &_name {
        color: #364469;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 3px !important;
      }

      &_nametitle {
        font-size: 10px;
        color: #5d5d5d;
        margin: 3px 0 12px 0;
      }

      &_count {
        font-size: 14px;
        color: #83889d;
        margin: 8px 0;
      }
    }
  }

  &__view {
    color: #425466;
    font-size: 14px;
    text-decoration: underline;
    margin: 0;
  }

  &__type {
    border-top: 1px solid #e4ebf5;
    padding-top: 12px;

    &_p {
      background-color: #f3f7fb;
      border: 1px solid #e4ebf5;
      padding: 4px 8px;
      border-radius: 8px;
      margin: 0;
      font-size: 12px;
      line-height: 13.2px;
      color: #364469;
    }

    &_affiliate {
      background-color: #fef4ee;
      border: 1px solid #fde5d7;
      padding: 4px 8px 4px 8px;
      border-radius: 8px;
      margin: 0;
      font-size: 12px;
      color: #f69b74;
    }

    &__centers {
      border: 1px solid #b0b0b0;
      background: #ffffff;
      color: #5d5d5d;
      font-size: 12px;
      line-height: 13.2px;
      border-radius: 8px;
      padding: 4px 8px;
    }

    &_nametitle {
      font-size: 10px;
      font-weight: 400;
      line-height: 13px;
      margin-top: 2px;
      padding-left: 4px;
      color: #5d5d5d;
    }

    &__package {
      font-size: 12px;
      line-height: 13.2px;
      border-radius: 8px;
      border: 1px solid #fde5d7;
      background-color: #fef4ee;
      color: #f69b74;
      padding: 4px 8px;
    }

    &_count {
      background-color: #ffffff;
      border: 1px solid #e4ebf5;
      padding: 4px 8px 4px 8px;
      border-radius: 8px;
      font-size: 12px;
      color: #425466;
      text-align: center;
      margin: 0;
      font-size: 12px;
      color: #425466;
    }
  }
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;

  img {
    width: 70px;
    height: 70px;
    margin: 0 auto;
  }
}

.selectedProcedures {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  word-break: break-word;
  height: auto;
  max-height: 150px;
  overflow: scroll;
  margin-bottom: 15px;

  &__tag {
    border: 1px solid #c1c6d3;
    padding: 6px;
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
    background: #fff;
    border-radius: 10px;

    img {
      width: 16px;
      height: 16px;
    }

    p {
      margin: 0;
      font-size: 12px;
    }
  }

  &__subCategoryList {
    height: 200px;
    overflow: scroll;
  }
}

.subCategoryList {
  height: 600px;
  overflow: scroll;
}

.active {
  background-color: #f0f0f0;
  border: 2px solid #007bff;
}

.actionBox {
  &__search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__btn {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.filterBtn {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
}

.filterModal {
  &__title {
    font-weight: 400;
    font-size: 16px;
    color: #364469;
    margin: 30px 0px 10px 0px;
  }

  &__dateRange {
    display: flex;
    justify-content: space-between;
    gap: 4%;
    align-items: center;
    margin: 0px 0px 30px 0px;
  }

  &__dateRange2 {
    display: flex;
    justify-content: space-between;
    gap: 4%;
  }

  &__inputGap {
    margin-top: 30px;
  }

  &__header {
    @extend .filterModal__dateRange;
    border-bottom: 2px solid #edf2f7;
    padding-bottom: 10px;

    h2 {
      font-weight: 500;
      font-size: 18px;
      color: #364469;
    }

    &__cancelButton {
      border-radius: 50%;
      padding: 5px 8px;
      border: none !important;
    }
  }

  &__button {
    @extend .filterModal__dateRange;
    width: unset;
    margin-top: 50px;
  }
}

.create {
  &__form {
    width: 400px;
    margin: 0 auto;

    &__input {
      margin-bottom: 16px;
    }
  }

  &__textarea {
    width: 100%;

    p {
      font-size: 14px;
      color: #425466;
      line-height: 16px;
      margin-bottom: 8px;
    }

    textarea {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #c1c6d3;
      padding: 9px 16px;
      box-sizing: border-box;
      outline: none;
      height: 100px;
    }
  }

  &__procedureTypes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 53.5px 0 12px 0;

    select {
      padding: 8px;
      border: 1px solid #e4ebf5;
      font-size: 12px;
      min-width: 88px;
      color: #425466;
      line-height: 13.2px;
      border-radius: 8px;
      outline: none;
    }

    p {
      color: #425466;
      font-size: 16px;
      line-height: 16px;
    }

    div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__tag {
    border: 1px solid #e4ebf5;
    background: #f3f7fb;
    padding: 11px 16px;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 4px;
    border-radius: 24px;
    margin-bottom: 24px;

    p {
      margin: 0;
      font-size: 14px;
      color: #364469;
    }
  }

  &__selected {
    background: #262d45;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 48px;
    max-height: 150px;
    overflow: scroll;
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;

    div {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      p {
        margin: 0;
        font-size: 14px;

        &:first-child {
          color: white;
        }

        &:last-child {
          background: #ffffff;
          color: #425466;
          padding: 4px 8px;
          border-radius: 12px;
        }
      }

      img {
        cursor: pointer;
      }
    }
  }
}

.viewCategory {
  &__container {
    padding: 27px 88px;
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  &__caption {
    font-size: 14px;
    color: #425466;
    line-height: 18.2px;
    margin-bottom: 8px;
    font-weight: 400;
  }

  &__description {
    color: #2d2d2d;
    line-height: 20.48px;
    font-size: 16px;
    word-break: break-word;
  }

  &__title {
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 500;
    color: #2d2d2d;
    margin: 0 0 16px 0;
  }
}

.centerDetails {
  padding: 40px 90px 0px 90px;

  &__fullservice {
    background-color: #f3f7fb;
    border: 1px solid #e4ebf5;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    color: #425466;
    font-size: 14px;
    width: fit-content;
    margin-bottom: 16px;
  }

  &__affiliate {
    background-color: #fef4ee;
    border: 1px solid #fde5d7;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    color: #f69b74;
    font-size: 14px;
    width: fit-content;
    margin-bottom: 16px;
  }

  h6 {
    font-size: 14px;
    color: #425466;
    margin: 0;
    padding-bottom: 8px;
  }

  h4 {
    margin: 0;
    font-size: 16px;
    color: #2d2d2d;
    padding-bottom: 16px;
    font-weight: 400;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    color: #2d2d2d;
    padding: 0 0 16px 0;
    margin: 0;
  }

  &__hours {
    display: flex;
    gap: 100px;
  }

  &__time {
    display: flex;
    justify-content: center;
    gap: 8px;

    h4 {
      background-color: #fafafa;
      border: 1px solid #e4ebf5;
      padding: 4px 8px 4px 8px;
      border-radius: 4px;
      margin-bottom: 8px;
    }
  }
}

.homePagePackagesContainer {
  width: 100%;
  padding: 20px;
}

.gridItem {
  // background: white;
  // border: 1px solid #ddd;
  // border-radius: 4px;
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  // transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  // overflow: hidden;

  // This ensures the content fits within the grid cell
  height: 100%;
  width: 100%;
}

.dragHandle {
  cursor: move;
  background-color: #f0f0f0;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ddd;

  &:hover {
    background-color: #e0e0e0;
  }
}

.duplicatePackageContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 18px;

  input {
    width: 18px;
    height: 18px;
    border: 3px solid #364469;
    border-radius: 12px;
  }

  p {
    font-size: 14px;
    color: #5d5d5d;
    margin: 0;
  }
}
