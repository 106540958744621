@import 'assets/styles/style.scss';
@import 'assets/styles/fonts.scss';

body {
  font-family: 'Inter', sans-serif;
}

.capitalize {
  text-transform: capitalize;
}

.transition-fade-enter {
  opacity: 0;
}

.transition-fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.transition-fade-exit {
  opacity: 1;
}

.transition-fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.svg-icon svg {
  position: relative;
  height: 1.5em;
  width: 1.5em;
}

.svg-text svg {
  stroke: #424242;
}

.svg-180 svg {
  transform: rotate(180deg);
}

.form-input {
  padding: 0.375rem;
  background-color: #eeeeee;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #424242;
}

.form-input:focus {
  outline: none;
  box-shadow: 0 0 1px 2px #8ecae6;
}

.is-fullwidth {
  width: 100%;
}

.bg-white {
  background-color: white;
}

.data-input {
  white-space: pre-wrap;
  border: none;
  padding: 0.5rem;
  color: #424242;
  font-size: 1rem;
  border-radius: 4px;
  resize: none;
  background-color: white;
  box-sizing: border-box;
  flex: 1 1 auto;
}

.data-input:focus {
  outline: none;
}

.shadow-5 {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.12),
    0 4px 6px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.12), 0 16px 32px rgba(0, 0, 0, 0.12);
}

.svg-icon-sm svg {
  position: relative;
  height: 1.25em;
  width: 1.25em;
}

.svg-gray svg {
  stroke: #9e9e9e;
}

.option-input {
  width: 100%;
  font-size: 1rem;
  border: none;
  background-color: transparent;
}

.option-input:focus {
  outline: none;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  overflow: hidden;
}

.sort-button {
  padding: 0.25rem 0.75rem;
  width: 100%;
  background-color: transparent;
  border: 0;
  font-size: 0.875rem;
  color: #757575;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
}

.sort-button:hover {
  background-color: #eeeeee;
}

.tr:last-child .td {
  border-bottom: 0;
}

.add-row {
  color: #9e9e9e;
  padding: 12px;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
}

.add-row:hover {
  background-color: #f5f5f5;
}

.th {
  color: white;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  background: #364469;
  border: none;
}

.th-content {
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.td {
  overflow: hidden;
  color: #424242;
  align-items: stretch;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.no-selected td {
  border-bottom: 1px solid #edf2f7 !important;
  border-right: 1px solid #edf2f7 !important;
  pointer-events: none;
}

.td-content {
  display: block;
}

.table {
  border-spacing: 0;
  border-top: 1px solid #edf2f7;
  border-bottom: 1px solid #edf2f7;
  max-width: 100%;
  overflow: scroll;
  border: 1px solid #edf2f7 !important;
}

.th:last-child {
  border-right: 0;
}

.td:last-child {
  border-right: 0;
}

.resizer {
  display: inline-block;
  background: transparent;
  width: 8px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  cursor: col-resize;
  touch-action: none;
}

.th,
.td {
  white-space: nowrap;
  margin: 0;
  border-bottom: 1px solid #edf2f7 !important;
  border-right: 1px solid #edf2f7 !important;
  position: relative;
}

.text-align-right {
  text-align: right;
}

.cell-padding {
  padding: 0.5rem;
}

.d-flex {
  display: flex;
}

.cursor-default {
  cursor: default;
}

.align-items-center {
  align-items: center;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.border-radius-md {
  border-radius: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-margin {
  margin-right: 4px;
}

.font-weight-600 {
  font-weight: 600;
}

.font-size-75 {
  font-size: 0.75rem;
}

.flex-1 {
  flex: 1;
}

.no-selected {
  color: 'red';
}

.ck-content {
  .table {
    margin: 0px 15px !important;
  }
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border: 1px solid #edf2f7;
  min-height: 260px !important;
  outline: none !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: 1px solid #edf2f7;
  min-height: 260px !important;
  outline: none !important;
}

.ck.ck-editor__editable {
  border: 1px solid grey !important;
}

canvas {
  width: 100% !important;
}

.react-css-collapse-transition {
  transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.lineBreak {
  white-space: pre-line;
}

.css-13cymwt-control {
  min-height: 54px !important;
  border-radius: 8px !important;
}

.css-1u9des2-indicatorSeparator {
  background-color: #fff !important;
  margin: 0px !important;
}

.css-1fdsijx-ValueContainer {
  min-height: 54px !important;
}

.css-t3ipsp-control {
  border-color: hsl(0, 0%, 80%) !important;
  box-shadow: none !important;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 15px !important;
}

input[type='date' i] {
  color: #808696;
  border: none !important;
  font-size: 13px;
}

input[type='datetime-local' i] {
  font-size: 14px;
  text-decoration-line: underline;
  color: #364469;
  border: none !important;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  position: relative !important;
  width: 100%;
}

//toggleSwitch
.switch {
  position: relative;
  display: inline-block;
  width: 49px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid #c1c6d3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 5px;
  bottom: 4px;
  background-color: #c1c6d3;
  box-shadow: 0px 0px 12px 3px #00000029;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: transparent;
  border: 1px solid #ed9373;
}

input:checked + .slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 5px;
  bottom: 4px;
  background-color: #ed9373;
  box-shadow: 0px 0px 12px 3px #00000029;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

tr,
td {
  page-break-inside: avoid !important;
}

// .highlight {
//   .avoid-page-break {
//     page-break-inside: avoid !important;
//   }

//   img {
//     page-break-inside: avoid !important;
//   }

//   tr {
//     page-break-inside: avoid !important;
//     color: red !important;
//   }

//   td {
//     color: red !important;
//   }
// }

@media print {
  .file-previewer {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}

.country {
  display: flex !important;
}
