.radioButton {
  input {
    accent-color: #ed4f32;
    width: 15px;
    height: 15px;
  }

  display: flex;
  align-items: center;
  //   margin-bottom: 12px;

  p {
    margin-left: 12px;
    font-weight: 500;
    line-height: 17.4px;
    font-size: 12px;
  }
}
// input[type='radio' i] {
//   background-color: #ed4f32 !important;
//   accent-color: #ed4f32;
//   border: none !important;
// }
