.drop {
  position: absolute;
  margin-left: -125px;
  margin-top: 8px;
  min-width: 165px;
  background: white;
  box-shadow: 0px 7.52055px 16.7123px rgba(171, 180, 189, 0.23);
  border-radius: 4px;
  border: 1px solid #d7dce0;
  z-index: 1;

  &__imageLeft {
    margin: 5px 10px 0 0;
  }
  p {
    margin: 0;
  }
  button {
    outline: none;
    background: white;
    border: none;
  }
  &__optionContainer {
    display: flex;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    &:hover {
      background-color: #edf2f7;
      border-radius: 4px;
    }
  }

  &__large {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    font-size: 12px;
    line-height: 2px;
    &:hover {
      background-color: #edf2f7;
    }
  }
}

.menuButton {
  outline: none;
  background: none;
  border: none;
}
