.skeleton {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 2rem;
  width: 35%;
  &__circle {
    width: 84px;
    height: 84px;
    margin: auto;
    border-radius: 40px;
    background: linear-gradient(to right, #eff1f3 4%, #f8f9fb 25%, #eff1f3 56%),
      linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
    animation: loading 1.5s infinite;
    @keyframes loading {
      to {
        background-position: 315px 0, 0 0, 0 190px, 50px 195px;
      }
    }
  }

  &__amount {
    display: flex;
  }

  &__form {
    height: 50px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #c1c6d3;
    border-radius: 8px;
    margin: 20px;
    background: linear-gradient(to right, #eff1f3 4%, #f8f9fb 25%, #eff1f3 56%),
      linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
    background-repeat: no-repeat;
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
    animation: loading 1.5s infinite;
    @keyframes loading {
      to {
        background-position: 315px 0, 0 0, 0 190px, 50px 195px;
      }
    }
  }
  &__flex {
    display: flex;
    flex-direction: row;
    &__child {
      width: 45%;
      height: 50px;
      box-sizing: border-box;
      background: linear-gradient(to right, #eff1f3 4%, #f8f9fb 25%, #eff1f3 36%),
        linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
      background-repeat: no-repeat;
      border: 1px solid #c1c6d3;
      border-radius: 8px;
      margin: 13px;
    }
  }
}
