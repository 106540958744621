.container {
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
  }

  input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 15.42px;
    height: 15.42px;
    top: 0;
    left: 0;
    background-color: white;
    border: 1.5px solid #83889d;
    border-radius: 3px;
  }

  input[type='checkbox']:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    background-color: #ed9373;
    border-radius: 3px;
    border: none;
  }

  input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
  }
}
