@use './mixin' as *;

.grid {
    display: grid;
    width: 100%;

    &__layout {
        @extend .grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 1.5rem;
        @include grid-generator(12);

        &--8 {
            grid-template-columns: repeat(8, 1fr);
            @include grid-generator(8);
        }

        &--4 {
            grid-template-columns: repeat(3, 1fr);
            @include grid-generator(3);
        }

        &--10 {
            grid-template-columns: repeat(10, 1fr);
            @include grid-generator(10);
        }

        &--mb {
            @include query('mobile') {
                display: block;
            }
        }

        @for $i from 0 through 20 {
            &.gap-#{$i} {
                grid-gap: #{$i}rem;
            }

            @include query('mobile') {
                &.row-gap-#{$i} {
                    grid-row-gap: #{$i}rem !important;
                }

                &.col-gap-#{$i} {
                    grid-column-gap: #{$i}rem !important;
                }

                &.no-col-gap {
                    grid-column-gap: 0 !important;
                }

                &.no-row-gap {
                    grid-column-gap: 0 !important;
                }
            }
        }
    }

    @include query('mobile') {
        @for $i from 1 through 20 {
            &--mobile {
                grid-template-columns: repeat(12, 1fr);
                @include grid-generator(12, mobile);

                &.gap-#{$i} {
                    grid-gap: #{$i}rem;
                }
            }
        }
    }

}