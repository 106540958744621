.modal,
.modal2 {
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  opacity: 1;
  right: 0;
  top: 0;
  background: rgba(163, 156, 156, 0.56);
  backdrop-filter: blur(0px);
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.modal__content {
  background-color: #fff;
  min-width: 414px;
  // max-width: 840px;
  min-height: 418px;
  max-height: 700px;
  margin: auto;
  box-sizing: border-box;
  position: relative;
  padding: 21px 30px;
  overflow: scroll;
}

.modal__content2 {
  background-color: #fff;
  max-width: 989px;
  height: 100%;
  margin: auto;
  box-sizing: border-box;
  float: right;
  right: 0;
  overflow: auto;
  margin-bottom: 50px;
}

.rightModal {
  display: flex;
  border-radius: 4px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  opacity: 1;
  right: 0;
  top: 0;
  background: rgba(163, 156, 156, 0.56);
  backdrop-filter: blur(0px);
}

.rightModal__content {
  background-color: #fff;
  width: 360px;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  padding: 21px 30px;
  overflow: scroll;
}
.overlay {
  position: fixed;
  inset: 0;
}
