.select {
  &__wrapper {
    position: relative;

    ul {
      padding: 0px 0;
    }
  }
  &__blurColor {
    color: hsl(0, 0%, 60%);
    font-size: 14px;
    font-weight: 400;
  }
  &__btn {
    height: 56px;
    border-radius: 4px;
    border: 1px solid #c1c6d3;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #575a65;
    padding: 0.3rem 1rem;
    background: #fff;
  }
  &_smHeight {
    height: 40px;
    border-radius: 4px;
    border: 1px solid #c1c6d3;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #575a65;
    padding: 0.3rem 1rem;
    background: #fff;
  }

  &__sm {
    height: 40px;
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-left: 13px;
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 1px);
    width: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.03);
    overflow-y: auto;
    max-height: 200px;
    z-index: 1000;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 0.875rem;

    &:hover {
      background: #f5f5f4;
    }

    &.active {
      color: #363740;
      background: #f2f2f2;
    }
  }

  &__disabled {
    border: 1px solid #c1c6d3;
    background: #ecedf1;
    cursor: default;
  }

  &__label {
    position: relative;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2%;
    color: #425466;
  }
  &__reactSelectLabel {
    position: relative;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 2% 0;
    color: #425466;
  }
}
