.indicatorWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
}

.formlayout {
  &__container {
    padding: 24px;
    background: #fafafa;
  }

  &__content {
    background-color: white;
    min-height: 80vh;
    padding: 60px 0;
  }

  &__button {
    padding: 0 12%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    button {
      display: flex;
      align-items: center;
    }
  }

  &__form {
    width: 400px;
    margin: auto;

    h2 {
      text-align: center;
      font-size: 24px;
      color: #0b2253;
      line-height: 32px;
      font-weight: 500;
      margin-bottom: 48px;
    }

    &_button {
      margin-top: 24px;
    }
  }
}

.title {
  font-size: 24px;
  color: #0b2253;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 48px;
}

.permission {
  &__table {
    padding: 0 12%;

    &__view {
      color: #0b2253;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin: 24px 0 20px 0;

    p {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #0b2253;
    }
  }
}

.edit_permission {
  &__heading {
    font-size: 18px;
    line-height: 32px;
    color: #0b2253;
    font-weight: 600;
  }

  &__button {
    text-align: center;
    margin-top: 44px;
  }
}

.role__name {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.avatar {
  span {
    background-color: #e9e5ff;
  }
}

.container {
  td {
    padding: 16px;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  border-spacing: 0px;
  border-collapse: inherit;
  border: none;
  word-break: unset !important;

  &__header {
    th {
      padding: 16px;
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      color: #425466;
      background: #fafafa;
      text-transform: capitalize;
    }
  }

  &__row {
    position: relative;
    border-bottom: 1px solid #edf2f7;

    td {
      border-bottom: 1px solid #edf2f7;
      padding: 24px 16px;
      font-size: 14px;
      vertical-align: middle;
      color: #425466;
      background: #ffffff;
      min-width: 215px !important;
      word-break: keep-all;
      word-wrap: break-word;
    }

    td:nth-child(6) {
      padding: 24px 16px;
      font-size: 14px;
      vertical-align: middle;
      color: #27272e;
    }
  }
}

.table tr {
  display: table-cell;
  vertical-align: baseline !important;
}

.table tr td {
  display: block;
}

.modal {
  &__header {
    font-weight: 500;
    font-size: 24px;
    color: #0b2253;
    line-height: 32px;
  }

  &__caption {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #425466;

    p {
      margin: 12px 0 26px 0;
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 12px 69px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      outline: none;
      border: none;
      cursor: pointer;
    }

    &_cancel {
      color: #425466;
      border: 1px solid #b5b5bd;
      border-radius: 8px;
    }

    &_save {
      background: #364469;
      border-radius: 8px;
      color: #ffffff;
    }
  }
}

.single_permission {
  p {
    margin: -15px 0 0 31px;
  }
}

.filterBtn {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
}
