.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px;
  gap: 5%;
  background: #ffffff;
  border: 1px solid #edf2f7;
  border-radius: 4px;
  min-width: none;

  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12.5px;
    line-height: 20px;
    color: #364469;
    padding-left: 10px;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #83889d;
    padding-left: 10px;
  }

  &__child {
    padding-top: 1rem;
    cursor: pointer;
    padding-right: 10px;
  }
}

.card_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  h1 {
    font-size: 16px;
  }
}

.indicator {
  &.deactivated {
    .card_flex_text {
      color: #c1c6d3;
      font-weight: 500;
    }
  }
}
