.sidebar {
  &__wrapper {
    width: 17%;
    background: #364469;
    border-radius: 16px;
    padding: 2.5rem 1rem 2rem 1rem;
    position: fixed;
    overflow-y: scroll;
    top: 25px;
    bottom: 25px;
  }

  &__divider {
    opacity: 0.08;
    border-radius: 4px;
    background: #ffffff;
    height: 2px;
    width: 100%;
    margin: 20px 0;
  }
}

.active {
  .menu {
    &__link {
      background: #ed9373;
      font-weight: 500;
      color: #fff;
      border-radius: 8px;

      img {
        svg {
          fill: white !important;
        }
      }
    }
  }
}

.menu {
  margin-top: 37px;
  // padding: 0 1rem 6.25rem;
  font-size: 1rem;

  &__link {
    padding: 0.75rem 1rem;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    display: flex;
    text-decoration: none;
    align-items: center;
    text-decoration: none;
    list-style-type: none;

    img {
      width: 24px;
      height: 24px;
    }

    span {
      margin-left: 16px;
    }
  }

  a {
    text-decoration: none;
  }
}
