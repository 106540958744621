.mainform {
  display: flex;
  justify-content: center;
  margin: 39px auto;

  &__skip {
    text-align: center;
    cursor: pointer;
  }

  &__input {
    padding-bottom: 20px;
  }
  &__address {
    background-color: #364469;
    padding: 8px 12px 8px 12px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    margin-top: 8px;
  }

  &__top {
    display: flex;
    gap: 3%;
  }

  &__select {
    width: 50%;
  }
  &__assignSelect {
    width: 50%;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin-bottom: 16px;
    &__label {
      font-size: 16px;
      font-weight: 400;
      color: #425466;
    }
  }

  &__links {
    font-weight: 500;
    font-size: 16px;
    color: #ed9373;
    text-align: start;
    cursor: pointer;
  }

  &__inputFlex {
    display: flex;
    justify-content: space-between;
  }

  &__double {
    display: flex;
    align-items: center;
    gap: 4%;
  }

  &__cancel {
    display: flex;
    justify-content: flex-end;
    margin-top: -4px;
    cursor: pointer;
  }
}

.viewform {
  display: flex;
  justify-content: center;
}

.edit__user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-left: 15%;
  width: 70%;

  &__form {
    display: flex;
    justify-content: center;
    gap: 3%;
  }

  &__form__sibling {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__avatar {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 2rem;
  }
}

.edit__center {
  display: flex;
  width: 400px;
  margin: auto;
  flex-direction: column;

  &__top {
    display: flex;
    gap: 3%;
  }

  &__form {
    display: flex;
    gap: 10px;
    margin-top: 6%;

    &__sibling {
      padding-bottom: 5%;
    }

    &__input {
      padding-bottom: 20px;
    }
  }

  &__double {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3%;
    gap: 3%;
  }

  &__select {
    width: 50%;
    margin-top: -1%;
  }

  Button {
    margin-top: 4%;
  }

  &__relative {
    margin-top: 3%;
  }
}

.location__container {
  background: #fafafa;
  width: 25rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.03);

  &__child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 0.875rem;

    &:hover {
      background: #f5f5f4;
    }
  }
}

.invite__user {
  display: flex;
  flex-direction: column;
  min-width: 30%;

  Button {
    margin-top: 5%;
  }

  &__nth {
    margin-top: 2%;

    .procedure {
      form {
        width: 85%;
        margin: 30px auto;
      }

      &__categories {
        height: 50vh;
        overflow: scroll;
        margin-bottom: 40px;
      }

      &__btn {
        text-align: center;
      }
    }
  }
}

.procedure {
  form {
    width: 85%;
    margin: 30px auto;
  }

  &__categories {
    height: 50vh;
    overflow: scroll;
    margin-bottom: 40px;
  }

  &__btn {
    text-align: center;
  }
}

.clock {
  display: flex;
  gap: 11px;
  align-items: center;
  cursor: pointer;

  p {
    font-size: 16px;
    font-weight: 400;
    color: #425466;
  }
}
.workingHours {
  span {
    width: 100%;
    padding: 4px 8px 4px 8px;
    gap: 8px;
    background-color: #fafafa;
    border-radius: 4px;
    border: 1px solid #e4ebf5;
  }
}
.workingHoursContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}
.hoursModal {
  position: absolute;
  top: calc(73% + 1px);
  left: 520px;
  width: 25%;
  background-color: white;
  padding: 24px;
  box-shadow: 0px 20px 24px -4px #0000001a;
  border-radius: 16px;
  z-index: 100;

  &__week {
    display: flex;
    gap: 10px;
  }

  p {
    padding: 6px;
    margin: 0;
  }
}
.hourModal {
  position: absolute;
  top: calc(82% + 1px);
  left: 520px;
  width: 25%;
  background-color: white;
  padding: 24px;
  box-shadow: 0px 20px 24px -4px #0000001a;
  border-radius: 16px;
  z-index: 100;

  &__week {
    display: flex;
    gap: 10px;
  }

  p {
    padding: 6px;
    margin: 0;
  }
}

.image {
  &__wrapper {
    border: 2px dashed #d7dce0;
    width: auto;
    padding: 14px 10px;
    border-radius: 4px;
    cursor: pointer;

    label {
      font-size: 16px;
      line-height: 24px;
      color: #81909d;
      margin: 0 0 10px 0;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
    }
  }

  width: auto;
  border: 1.03268px dashed #edf2f7;
  height: auto;
  padding: 30px 15px;
  text-align: center;
  color: #808080;
  font-size: 14px;
  cursor: pointer;

  p {
    margin: 5px 0;
  }

  img {
    width: 24px;
  }
}

.displayImage {
  display: flex;
  margin: auto;
  width: 100%;
  object-fit: cover;
}

.hide {
  display: none;
}

.show {
  display: block;
}
