.header {
  background: #fff;
  color: #425466;
  border-radius: 4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.875rem;
  z-index: 2;
  position: relative;

  button {
    background: white;
    border: none;
    cursor: pointer;
  }

  &__group {
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0.625rem;

    p {
      margin: 0;
    }
  }

  &__notification {
    img {
      margin-right: 30px;
      cursor: pointer;
    }
  }

  &__btn {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
  }

  &__profile {
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }

  &__title {
    color: #364469;
    font-weight: 600;
    font-size: 28px;
  }
}

h2 {
  margin: 0;
}

.nav {
  &__link {
    text-decoration: none;
  }
}

.dropdown {
  position: absolute;
  background: #fff;
  margin-left: 0.8rem;
  margin-top: 0.5rem;
  min-width: 210px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  font-size: 0.75rem;

  &__header {
    padding: 0.625rem 1rem;
  }

  &__label {
    font-size: 0.625rem;
    text-transform: uppercase;
    display: block;
    padding-top: 0.5rem;
  }

  &__item {
    font-size: 0.875rem;
    padding: 0.625rem 1rem;
    display: flex;
    align-items: center;
    border-top: 1px solid #d9d9d9;
    width: 100%;

    svg {
      margin-right: 0.5rem;
      width: 1.5rem;
    }
  }
}

.admin {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  margin-right: 12px;

  &__role {
    margin-top: 2px;
    color: #83889d;
  }
}

.drop_caption {
  font-size: 12px;
  font-weight: 400;
  color: #83889d;
  font-weight: 400;
}

.logout {
  &__header {
    font-weight: 500;
    font-size: 24px;
    color: #0b2253;
    line-height: 32px;
  }

  &__caption {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #425466;
    p {
      margin: 12px 0 26px 0;
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 12px 69px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      outline: none;
      border: none;
      cursor: pointer;
    }

    &_cancel {
      color: #425466;
      border: 1px solid #b5b5bd;
      border-radius: 8px;
    }

    &_logout {
      background: #ca1b1b;
      border-radius: 8px;
      color: #ffffff;
    }
  }
}
