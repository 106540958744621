.main {
  &__layout {
    padding: 24px;
    flex: 1;
    display: flex;
    height: 100%;
    column-gap: 2%;
    background: #fafafa;
    background-color: white;
  }

  &__page {
    width: 73%;
    min-height: 100vh;
    position: relative;
    left: 22%;
    background: #ffffff;
    border-radius: 16px;
    padding: 24px 32px;
  }

  &__form {
    // width: 73%;
    // min-height: 100vh;
    // position: relative;
    // left: 13%;
    background: #ffffff;
    border-radius: 16px;
    padding: 24px 32px;
  }
  &__title {
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #0b2253;
    margin-bottom: 0;
  }
  &__caption {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #425466;
  }
}

.formButton {
  display: flex;
  justify-content: space-between;
  padding: 3% 12% 0 12%;
}

.FormLayoutContainer {
  // padding: 60px 300px;
  // text-align: center;
  align-items: center;
}

.homepage {
  &__actionbox {
    display: flex;
    width: unset;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 24px;

    button {
      width: unset;
    }
  }

  &__export {
    margin-right: 16px;
  }

  &__export_caption {
    display: flex;
    align-items: center;
    margin-right: 16px;

    img {
      margin-right: 12px;
    }

    p {
      margin: 0;
    }
  }
}
