.tabs {
  display: inline-flex;
  border-bottom: 2px solid #f2f2f2;
  width: 100%;
  overflow: scroll;

  &__item {
    display: inline-flex;
    font-size: 1rem;
    color: #7b7171;
    padding: 0.875rem 0;
    border-bottom: 2px solid transparent;
    margin: 0 1rem;
    position: relative;
    cursor: pointer;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.active {
      color: #ed9373 !important;
      border-color: #ed9373;

      .tabs__id {
        color: #ed9373;
        border-color: #ed9373;
      }

      button {
        color: #ed9373 !important;
        text-shadow: 0.4px 0 0 currentColor;
      }
    }

    &.sm {
      padding-bottom: 0;
    }

    button {
      cursor: pointer;
      white-space: nowrap;
      display: flex;
      align-items: center;
      border: none;
      background: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #83889d;
    }

    &-disabled {
      cursor: default;

      & button:disabled {
        cursor: default;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    margin-right: 0.5rem;
  }

  &__id {
    display: none;
    border: 3px solid #dfe0eb;
  }

  &__alert-title {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.sm {
    border-bottom: none;
  }
}
ul {
  padding-inline-start: 0px !important;
}
