.notifications {
  &__drop {
    position: absolute;
    margin-top: 8px;
    background: white;
    min-width: 180px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 10px;
  }

  &__view {
    color: #ed9373;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 8px 0;
    text-decoration: underline;
    cursor: pointer;
  }

  &__form {
    width: fit-content;
    margin: auto;

    h1 {
      text-align: center;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #364469;
      margin: 24px 0;
    }

    &__container {
      border: 1px solid #edf2f7;
      border-radius: 8px;
      max-height: 60vh;
      min-height: auto;
      overflow: scroll;
      padding: 32px;

      &_card {
        padding: 16px;
        max-width: 422px;
        border-bottom: 1px solid #edf2f7;
        // height: 100px;

        &__text {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #425466;
        }

        &__date {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #b5b5bd;
        }

        &__time {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #b5b5bd;
        }

        &__button {
          text-align: right;
        }

        &:hover {
          background: #fafafa;
          border: 1px solid #edf2f7;
          border-radius: 8px;
          cursor: pointer;
        }
      }
    }
  }
}

.single_notification {
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  border-bottom: 1px solid #edf2f7;
  color: #000000;
  letter-spacing: 0.01em;

  &:hover {
    background: #fafafa;
  }

  &__date {
    font-weight: 400;
    font-size: 12px;
    color: #83889d;
    margin-top: 8px !important;
  }

  &__time {
    font-weight: 400;
    font-size: 12px;
    color: #83889d;
  }
}

.emptyNotifications {
  text-align: center;
  padding: 35px 0;

  img {
    margin: 0px;
    width: 36px;
  }

  p {
    margin-top: 8px;
    font-size: 14px;
    color: #425466;
    font-weight: 500;
  }
}

.notification_loading {
  margin-top: 20px;
  color: #b5b5bd;
  font-weight: 700;
}
