.flexSpacebtw {
  display: flex;
  justify-content: space-between;
}
.actionBox {
  &__search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
.filterBtn {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
}
.counts {
  margin: 4px 0 40px 0;
  color: #83889d;
}

.invoicesFilter {
  &_header {
    @extend .flexSpacebtw;
    gap: 4%;
    align-items: center;
    margin: 0px 0px 30px 0px;
    border-bottom: 2px solid #edf2f7;
    padding-bottom: 10px;

    h2 {
      font-weight: 500;
      font-size: 18px;
      color: rgba(54, 68, 105, 1);
      letter-spacing: 2%;
    }

    &_cancelButton {
      border-radius: 50%;
      padding: 5px 8px;
      border: none !important;
    }
  }

  &_title {
    color: rgba(54, 68, 105, 1);
    font-size: 16px;
    font-weight: 600;
  }

  &_selectTitle {
    color: rgba(54, 68, 105, 1);
    font-size: 16px;
    font-weight: 600;
    margin: 16px 0px 12px 0px;
  }

  &_dateRange {
    @extend .flexSpacebtw;
    gap: 4%;
    align-items: center;
    //  margin: 16px 0px 16px 0px;
  }

  &_button {
    @extend .flexSpacebtw;
    gap: 4%;
    align-items: center;
    margin: 30px 0px 30px 0px;
  }
}
.bookingsDetails {
  &_actionBtn {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 12px 16px;
    border: 1px solid #c1c6d3;
    border-radius: 4px;
    color: #425466;
    font-size: 14px;
  }

  &_heading {
    display: flex;
    gap: 10px;
    margin-top: -10px;

    h1 {
      font-size: 28px;
      color: #364469;
      font-weight: 500;
      margin: 0;
    }
  }

  &_heading2 {
    @extend .flexSpacebtw;
    gap: 10px;
    align-items: center;
    margin-top: -4px;
  }

  &_headingText {
    margin: 0 0 0 34px;
    color: #83889d;
    font-weight: 500;
    font-size: 16px;
  }

  &_container {
    padding: 32px;
    border: 1px solid #edf2f7;
    width: 90%;
    min-height: 517px;
    border-radius: 16px;
    margin-top: 39px;
  }

  &_info {
    @extend .flexSpacebtw;
    width: 100%;
    // padding-bottom: 30px;
    border-bottom: 2px solid #edf2f7;
    min-height: 246px;
  }

  &_infoContent {
    @extend .flexSpacebtw;
    padding-top: 20px;
  }

  &_captionText {
    font-size: 18px;
    color: #364469;
    font-weight: 600;
    margin: 0;
  }

  &_proceducerContent {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin-top: 20px;
    gap: 25px;
    height: 100%;

    p {
      margin-bottom: 5px;
    }
  }

  &_infoValue {
    color: #425466;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }

  &_infoTitle {
    color: #83889d;
    font-size: 12px;
    font-weight: 400;
    margin: 5px 0px 20px 0px;
  }
}
.invoicesDetails {
  &_heading {
    display: flex;
    gap: 10px;
    margin-top: -10px;

    h1 {
      font-size: 28px;
      color: #364469;
      font-weight: 500;
      margin: 0;
    }
  }
  &_details {
    margin-top: 48px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #83889d;
    }
    h6 {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #364469;
      margin-top: 8px;
    }
  }
}
.indicatorWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: capitalize;
  }
  .hq {
    &_filter {
      display: flex;
      align-items: center;
      margin: 20px 0 20px 0;
  
      p {
        margin: 0 0 0 10px;
        color: #425466;
        font-size: 14px;
      }
  
      input {
        margin: 0;
      }
    }
  }
