.btn {
  border-radius: 8px;
  text-align: center;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 16px;
  background: #f2f2f2;
  color: #363740;
  cursor: pointer;
  white-space: nowrap;

  &.outline {
    background: #364469;
    border: 2px solid #364469;
    background-color: #fff;
    color: #364469;
  }

  &__oblige {
    background: #fff;
    color: #364469;
    &:hover {
      box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.1);
    }
    border: 2px solid #364469;
  }

  &__obligeNw {
    background: #fff;
    color: #364469;
    &:hover {
      box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.1);
    }
    border: 1px solid rgba(131, 136, 157, 1);
  }
  &__closed {
    font-size: 15px;
    color: #ed9373;
    background: #ffffff;
    border: 1px solid #edf2f7;
    border-radius: 16px;
  }

  &__rejected,
  &__refresh {
    font-size: 15px;
    line-height: 16px;
    background: transparent;
  }
  &__rejected {
    color: #ca1b1b;
  }
  &__refresh {
    color: #ed9373;
  }

  &__primary {
    background: #364469;
    color: #fff;

    &:hover {
      box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.1);
    }

    &.outline {
      border: 2px solid #364469;
      background-color: #fff;
      color: #364469;
    }

    &:disabled {
      border: 2px solid #d3cccc;
      color: #d3cccc;
      background: #ededed;
    }

    svg,
    svg * {
      fill: #fff;
    }

    &.wide {
      min-width: 170px;
      padding: 0 1.5rem;
    }
  }

  &__secondary {
    border: 1px solid #c1c6d3;
    border-radius: 4px;
    color: #425466;

    &:hover {
      box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.1);
    }
  }

  &__tertiary {
    background: #fafafa;
    border: 1px solid #c1c6d3;
    border-radius: 20px;
    padding: 8px 16px;
  }

  &__orange {
    border: 1px solid #ed9373;
    background: #ed9373;
    border-radius: 8px;
    padding: 8px 16px;
    color: white;
  }

  &__plain_orange {
    border: 1px solid #ed9373;
    border-radius: 8px;
    background: #ffffff;
    padding: 8px 16px;
    color: #ed9373;
    margin-right: 12px;
  }

  &__plain {
    background: transparent;
  }

  &__sm {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__smfilter {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 32px;
  }

  &__smCancel {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    padding: 9px 16px;
  }

  &__md {
    padding: 1rem;
    min-width: 400px;
  }

  &__lg {
    height: 56px;
  }

  &__xl {
    font-weight: 600;
    height: 62px;
    font-size: 1rem;
    line-height: 21px;
    width: 100%;
  }

  &:disabled {
    background: #f2f2f2;
    color: #d3cccc;
    border: none;
    cursor: not-allowed !important;
    box-shadow: none;

    &:hover {
      background: #f2f2f2;
      color: #d3cccc;
    }
  }
}
