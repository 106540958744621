.input {
  border: 1px solid #c1c6d3;
  border-radius: 8px;
  padding: 1rem;
  font-size: 1rem;
  width: 100%;
  height: 56px;
  box-sizing: border-box;

  &::placeholder {
    color: #c1c6d3;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #ed9373 !important;
  }

  &__label {
    position: relative;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
    color: #425466;
  }

  &__btn {
    height: 22%;
    width: auto;
    position: relative;
    background: none;
    border: none;
    right: -45%;
    cursor: pointer;
    top: -2.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      background: none;
    }
  }

  &:disabled {
    border: 1px solid #c1c6d3;
    background: #ecedf1;
    color: black;
    font-weight: 100;
    cursor: not-allowed !important;
    box-shadow: none;
  }
}

.date_picker input {
  background-color: #ffffff;
  border: 1px solid #c1c6d3;
  border-radius: 8px;
  padding: 17px;
  font-size: 1rem;
  width: 100%;
  height: 55px;
  box-sizing: border-box;

  &::placeholder {
    color: #575a65;
    font-size: 16px;
    line-height: 24px;
  }
}

.no_border input {
  border: none !important;
  font-size: 14px;
  background-color: transparent !important;
  width: 110px;
  text-decoration-line: underline;
  color: #83889d !important;

  &:focus {
    outline: none !important;
    border: none !important;
    width: 110px !important;
  }
}

// span {
//   display: flex;
// }
.calendarIcon {
  position: absolute;
  right: 0;
  bottom: 15px;
  z-index: 1;
  float: right;
  margin-right: 10px;
  color: #83889d;
}

.input_date {
  position: relative;
}

.spaceCalendarIcon {
  width: 15px !important;
  height: 15px !important;
  color: #83889d;
  bottom: 15px;
  left: 0;
  position: absolute;
}
.pi_button {
  background-color: white;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.pi_dropdown {
  width: 100%;
  max-width: 280px;
}
.pi_input {
  box-sizing: border-box;
  padding: 1rem;
  width: 100%;
  margin-left: 28px;
  font-size: 14px;
  line-height: 20px;
  height: 100%;
}
.flag-dropdown .selected-flag {
  border-radius: 0px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
